import * as Sentry from '@sentry/react';

import { isDev, isProd, sentryDsn } from '@/config/env';

type ErrorHint = Parameters<typeof Sentry.captureException>[1];

export const captureError = (error: unknown, hint?: ErrorHint) => {
  if (!isDev) Sentry.captureException(error, hint);
  // eslint-disable-next-line no-console
  else console.error('Sentry capture error', error);
};

export const initSentry = () => {
  if (!isDev) {
    Sentry.init({
      dsn: sentryDsn,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: isProd ? 1.0 : 0,
      integrations: isProd ? [Sentry.replayIntegration()] : [],
      ignoreErrors: ['Network Error', 'timeout of 30000ms exceeded', 'Request aborted'],
      environment: isProd ? 'production' : 'staging',
    });
  }
};
