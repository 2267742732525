import { URLSearchParamsInit, createSearchParams, generatePath, useNavigate } from 'react-router-dom';

import { Pages } from '@/navigation';

declare type _PathParam<Path extends string> = Path extends `${infer L}/${infer R}`
  ? _PathParam<L> | _PathParam<R>
  : Path extends `:${infer Param}`
    ? Param extends `${infer Optional}?`
      ? Optional
      : Param
    : never;
declare type PathParam<Path extends string> = Path extends '*' | '/*'
  ? '*'
  : Path extends `${infer Rest}/*`
    ? '*' | _PathParam<Rest>
    : _PathParam<Path>;

export const useAppNavigate = () => {
  const navigate = useNavigate();

  return <Page extends Pages>(
    pageName: Page,
    params?: {
      [key in PathParam<Page>]: string | null;
    },
    searchParams?: URLSearchParamsInit,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pathname = generatePath<any>(pageName, params);
    navigate({
      pathname,
      search: searchParams ? createSearchParams(searchParams).toString() : '',
    });
  };
};
