import BackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, MenuItem, Menu, Box, useMediaQuery, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogout } from '@/features/User';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { toggleThemeMode as toggleThemeModeAction } from '@/styles/theme';
import { useAppTranslation } from '@/translation';

import { Logo } from '../Logo';

import { HeaderBar, ProfileDropdownButton } from './styled';

export interface AppBarProps {
  showBackButton?: boolean;
}

const menuId = 'app-bar-user-menu';

export const AppBar = ({ showBackButton }: AppBarProps) => {
  const { t } = useAppTranslation();
  const { palette, breakpoints } = useTheme();
  const { currentUser } = useAppSelector((state) => state.user);
  const nativeNavigate = useNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const hideMenu = !currentUser;

  const { logout } = useLogout();

  const isLargerScreen = useMediaQuery(breakpoints.up('sm'));

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    handleMenuClose();
    logout();
  }, [logout]);

  const back = () => {
    nativeNavigate(-1);
  };

  const toggleThemeMode = useCallback(() => {
    dispatch(toggleThemeModeAction());
  }, [dispatch]);

  const renderMenu = useMemo(
    () => (
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            padding: '0 !important',
          },
        }}
      >
        {!isLargerScreen && (
          <MenuItem onClick={toggleThemeMode}>
            <ListItemIcon>
              <LightModeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('common:changeThemeMode')}</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout} data-test="app-bar_user-menu_logout">
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('user:logout')}</ListItemText>
        </MenuItem>
      </Menu>
    ),
    [anchorEl, handleLogout, isLargerScreen, isMenuOpen, t, toggleThemeMode],
  );

  return (
    <>
      <HeaderBar>
        <Stack direction="row" justifyContent="space-between">
          {showBackButton && (
            <IconButton size="large" aria-label="back" sx={{ mr: 2, color: 'white' }} onClick={back}>
              <BackIcon />
            </IconButton>
          )}

          <Logo linkToHome />

          <Box display={hideMenu ? 'none' : 'flex'} alignItems="center" paddingRight={4}>
            {isLargerScreen ? (
              <>
                <IconButton
                  onClick={toggleThemeMode}
                  size="large"
                  aria-label="toggle theme mode"
                  sx={{ mr: 2, color: palette.app.outline }}
                >
                  <LightModeIcon />
                </IconButton>
                <ProfileDropdownButton
                  variant="outlined"
                  onClick={handleMenuOpen}
                  aria-controls={menuId}
                  aria-label="menu"
                  endIcon={isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  data-test="app-bar_user-menu"
                >
                  {t('user:helloName', { name: currentUser?.username })}
                </ProfileDropdownButton>
              </>
            ) : (
              <IconButton
                onClick={handleMenuOpen}
                aria-controls={menuId}
                aria-label="menu"
                sx={{ color: palette.app.outline }}
                data-test="app-bar_user-menu"
              >
                <MenuIcon sx={{ height: 40, width: 'auto' }} />
              </IconButton>
            )}
          </Box>
        </Stack>
      </HeaderBar>
      {renderMenu}
    </>
  );
};
