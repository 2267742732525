import { useEffect } from 'react';

import { useAppDispatch } from '@/hooks';
import { axiosInstance } from '@/services';

import { setSession } from '../store';

import { useNavigateFromLogin } from './useNavigateFromLogin';
import { useRefreshCurrentUserData } from './useRefreshCurrentUserData';

interface SetSessionAndRedirectUserParams {
  authenticationMethod: 'email' | 'google';
}

export const useSetSessionAndRedirectUser = ({ authenticationMethod }: SetSessionAndRedirectUserParams) => {
  const dispatch = useAppDispatch();
  const { navigateToHomeOrFrom } = useNavigateFromLogin();
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const hasAxiosSetToken = !!axiosInstance.defaults.headers.common.Authorization;

  const setSessionAndRedirectUser = (accessToken: string, refreshToken: string) => {
    dispatch(setSession({ accessToken, refreshToken }));
  };

  useEffect(() => {
    if (!hasAxiosSetToken) return;

    refreshCurrentUserData();

    navigateToHomeOrFrom();
  }, [authenticationMethod, navigateToHomeOrFrom, refreshCurrentUserData, hasAxiosSetToken]);

  return { setSessionAndRedirectUser };
};
