import { useQuery } from '@tanstack/react-query';

import { User } from '@/features/User/types/User';
import { updateTokenInterceptor } from '@/services';

export const getCurrentUserQueryKey = 'getCurrentUser';
// const endpoint = '/users/me/';

export const useGetCurrentUserApi = () => {
  const {
    data: user,
    isLoading,
    refetch,
  } = useQuery<User>({
    queryKey: [getCurrentUserQueryKey],
    queryFn: async () => {
      await updateTokenInterceptor();

      // const res = await axiosInstance.get<User>(endpoint);
      // return res.data;
      return {
        id: '1',
        username: '',
      };
    },
    enabled: false,
  });

  return {
    user,
    refetch,
    isLoading,
  };
};
