import { useAppSelector } from '@/hooks';
import { store } from '@/store';

import { refreshToken as refreshTokenRequest } from '../api';
import { setSession } from '../store';

export const useRefreshToken = () => {
  const { session } = useAppSelector((state) => state.user);

  const refreshToken = async () => {
    if (!session?.refreshToken) return false;

    const { accessToken } = await refreshTokenRequest({
      refreshToken: session?.refreshToken,
    });

    if (accessToken) store.dispatch(setSession({ accessToken, refreshToken: session.refreshToken }));
    else store.dispatch(setSession(null));

    return accessToken;
  };

  return { refreshToken };
};
