import { CssBaseline, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { ReactNode, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks';

import { darkModePalette, lightModePalette } from './colorThemeHandler';
import { muiComponentOverwrite } from './muiComponentOverwrite';
import { setThemeMode } from './themeSlice';
import { typography } from './typographyThemeHandler';

interface MaterialThemeProviderProps {
  children: ReactNode;
}

export const MaterialThemeProvider = ({ children }: MaterialThemeProviderProps) => {
  const dispatch = useAppDispatch();
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');
  const themeMode = useAppSelector((state) => state.theme.mode);

  // when themeMode is system update the themeMode based on the prefersLightMode
  useEffect(() => {
    if (themeMode === 'system') dispatch(setThemeMode(prefersLightMode ? 'light' : 'dark'));
  }, [prefersLightMode, themeMode, dispatch]);

  const theme = useMemo(() => {
    const getTheme = (): 'dark' | 'light' => {
      if (themeMode === 'system') return prefersLightMode ? 'light' : 'dark';
      return themeMode;
    };

    const currentPalette = getTheme() === 'dark' ? darkModePalette : lightModePalette;

    // if (currentPalette.background) currentPalette.background.default = 'transparent';

    return createTheme({
      palette: currentPalette,
      typography,
      spacing: 4,
      components: muiComponentOverwrite(currentPalette),
    });
  }, [prefersLightMode, themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
