import { Box, Container } from '@mui/material';

import { AppBar, AppBarProps } from '@/components/elements';

interface PageProps extends AppBarProps {
  children: React.ReactNode;
  overflowVisible?: boolean;
}

export const Page = ({ children, overflowVisible, ...appBarProps }: PageProps) => {
  return (
    <Box flex={1} width="100%" overflow={overflowVisible ? 'visible' : 'auto'}>
      <Container maxWidth="md" sx={{ mt: 4, px: 3, pb: 10 }}>
        <AppBar {...appBarProps} />
        <Box mt={8}>{children}</Box>
      </Container>
    </Box>
  );
};
