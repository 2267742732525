import { useTranslation } from 'react-i18next';

export enum Pages {
  login = '/login',
  home = '/home',
  reviewNewQuestion = '/questions/:type/review-new-question',
  reviewTranslation = '/questions/:type/review-translation/:toLanguage',
  reviewGameRules = '/questions/:type/review-game-rules',
}

export const getPagePath = (page: Pages, params?: Record<string, string | number>): string => {
  let path: string = page;

  if (params) {
    Object.keys(params).forEach((key) => {
      path = path.replace(`:${key}`, params[key].toString());
    });
  }

  return path;
};

export const useGetPagePathWithLang = (page: Pages, params?: Record<string, string | number>): string => {
  const { i18n } = useTranslation();
  return getPagePath(page, { lang: i18n.language, ...params });
};
