import { useUpdateSentryContextAboutUser } from '../../hooks';

// import { setSession } from '../../store';

interface SessionManagerProps {
  children: React.ReactNode;
}

export const SessionManager = ({ children }: SessionManagerProps) => {
  useUpdateSentryContextAboutUser();

  // useEffect(() => {
  // supabase.auth.getSession().then(({ data: { session } }) => {
  //   dispatch(setSession(session));
  // });
  // supabase.auth.onAuthStateChange((_event, session) => {
  //   dispatch(setSession(session));
  // });
  // }, [dispatch]);

  return <>{children}</>;
};
