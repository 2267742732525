import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/services';

import { BinaryQuestion } from '../types/questions';

interface useGetBinaryQuestionsParams {
  selected?: boolean;
  finished?: boolean;
  language?: string;
  disabled?: boolean;
}

export const useGetBinaryQuestionsApi = ({ selected, finished, language, disabled }: useGetBinaryQuestionsParams) => {
  const getBinaryQuestions = async () => {
    const response = await axiosInstance.get<BinaryQuestion[]>('/binary-choice-questions/', {
      params: {
        selected,
        finished,
        language,
      },
    });

    if (response.status === 404) return null;

    return response.data;
  };

  const {
    data: binaryChoiceQuestions,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [
      'binaryQuestions',
      {
        selected,
        finished,
        language,
      },
    ],
    queryFn: getBinaryQuestions,
    enabled: disabled !== true,
    staleTime: 5000, // 5 seconds
  });

  return {
    binaryChoiceQuestions,
    isLoading: isLoading || isRefetching,
    refetch,
  };
};
