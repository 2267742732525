import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/services';

import { MultipleQuestion } from '../types';

interface useGetMultipleChoiceQuestionsParams {
  selected?: boolean;
  finished?: boolean;
  language?: string;
  disabled?: boolean;
}

export type MultipleQuestionBackend = Omit<
  MultipleQuestion,
  | 'answerA'
  | 'answerB'
  | 'answerC'
  | 'answerD'
  | 'answerA_pl'
  | 'answerB_pl'
  | 'answerC_pl'
  | 'answerD_pl'
  | 'answerA_en'
  | 'answerB_en'
  | 'answerC_en'
  | 'answerD_en'
  | 'correctAnswer'
> & {
  A: string;
  B: string;
  C: string;
  D: string;
  A_pl: string;
  B_pl: string;
  C_pl: string;
  D_pl: string;
  A_en: string;
  B_en: string;
  C_en: string;
  D_en: string;
  correctAnswer: string;
};

export const useGetMultipleChoiceQuestionsApi = ({
  selected,
  finished,
  language,
  disabled,
}: useGetMultipleChoiceQuestionsParams) => {
  const getMultipleChoiceQuestions = async () => {
    const response = await axiosInstance.get<MultipleQuestionBackend[]>('/multiple-choice-questions/', {
      params: {
        selected,
        finished,
        language,
      },
    });

    if (response.status === 404) return null;

    return processMultipleChoiceQuestions(response.data);
  };

  const {
    data: multipleChoiceQuestions,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [
      'multipleChoiceQuestions',
      {
        selected,
        finished,
        language,
      },
    ],
    queryFn: getMultipleChoiceQuestions,
    enabled: disabled !== true,
    staleTime: 5000, // 5 seconds
  });

  return {
    multipleChoiceQuestions,
    isLoading: isLoading || isRefetching,
    refetch,
  };
};

const processMultipleChoiceQuestions = (questionsBackend: MultipleQuestionBackend[]): MultipleQuestion[] => {
  return questionsBackend.map((question) => ({
    ...question,
    correctAnswer: question.correctAnswer.split(','),
    answerA: question.A,
    answerB: question.B,
    answerC: question.C,
    answerD: question.D,
    answerA_pl: question.A_pl,
    answerB_pl: question.B_pl,
    answerC_pl: question.C_pl,
    answerD_pl: question.D_pl,
    answerA_en: question.A_en,
    answerB_en: question.B_en,
    answerC_en: question.C_en,
    answerD_en: question.D_en,
  }));
};
