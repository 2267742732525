import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Language, languagesResources } from './locales';

type Namespaces = keyof typeof languagesResources.pl;
type NestedKeys<T> = T extends object
  ? {
      [K in keyof T]: `${string & K}${NestedKeys<T[K]> extends '' ? '' : '.'}${NestedKeys<T[K]>}`;
    }[keyof T]
  : '';

type PluralPostfixes = '_zero' | '_one' | '_two' | '_few' | '_many' | '_other';
type FilterPluralPostfix<T> = T extends `${infer U}${PluralPostfixes}` ? U : T;

type NamespaceKeys<T extends Namespaces> = FilterPluralPostfix<NestedKeys<(typeof languagesResources.pl)[T]>>;
type NamespacedTranslationKeys<T extends Namespaces> = `${T}:${NamespaceKeys<T>}`;
export type TranslationKeys = {
  [T in keyof typeof languagesResources.pl]: NamespacedTranslationKeys<T>;
}[keyof typeof languagesResources.pl];

export type TranslationFunction = (key: TranslationKeys, params?: Record<string, unknown> | undefined) => string;

export const useAppTranslation = () => {
  const { t: nativeTranslate, i18n } = useTranslation();

  const currentLanguage = i18n.language as Language;

  const t = useCallback(
    (key: TranslationKeys | Array<TranslationKeys>, params?: Record<string, unknown>) => {
      if (params) return nativeTranslate(key, params);
      else return nativeTranslate(key);
    },
    [nativeTranslate],
  );

  const isTranslationExist = useCallback(
    (key: TranslationKeys, params?: Record<string, unknown>) => {
      const translation = nativeTranslate(key, params);
      return translation !== key;
    },
    [nativeTranslate],
  );

  return { t, isTranslationExist, i18n, currentLanguage };
};
