import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { ControlledPasswordField, ControlledTextField } from '@/components/form';
import { Meta } from '@/components/layout';
import { useAppSelector } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { LoginPageTemplate } from '../components';
import { useNavigateFromLogin, useLoginForm } from '../hooks';

export const LoginPage = () => {
  const { t } = useAppTranslation();
  const { session } = useAppSelector((state) => state.user);
  const { navigateToHomeOrFrom } = useNavigateFromLogin();

  useEffect(() => {
    if (session) navigateToHomeOrFrom();
  }, [navigateToHomeOrFrom, session]);

  const { form, onSubmitPress, isSubmitting: isLoading } = useLoginForm();

  return (
    <LoginPageTemplate headerMessageKey="user:welcomeBack">
      <Meta titleKey="user:signIn" />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => onSubmitPress())}>
          <Stack spacing={4} mt={4} mb={8}>
            <ControlledTextField
              id="email"
              name="email"
              type="email"
              label={t('user:emailAddress')}
              autoComplete="email"
              autoCapitalize="off"
              margin="normal"
              fullWidth
              alwaysShowLabel
            />
            <ControlledPasswordField
              id="password"
              name="password"
              label={t('user:password')}
              autoComplete="current-password"
            />
          </Stack>
          <Stack alignItems="center" spacing={8}>
            <LoadingButton
              type="submit"
              itemType="submit"
              variant="contained"
              sx={{ maxWidth: 300 }}
              fullWidth
              onClick={onSubmitPress}
              loading={isLoading}
              data-test="login-submit"
            >
              {t('user:signInUsingEmail')}
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </LoginPageTemplate>
  );
};
