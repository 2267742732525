import { createSlice } from '@reduxjs/toolkit';

import { User } from '@/features/User/types/User';

interface Session {
  accessToken: string;
  refreshToken: string;
}

interface UserState {
  currentUser: User | null;
  session: Session | null;
}

const initialState: UserState = {
  currentUser: null,
  session: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSession: (state, { payload }: { payload: UserState['session'] }) => {
      state.session = payload;
    },
    setCurrentUserData: (state, { payload }: { payload: UserState['currentUser'] }) => {
      state.currentUser = payload;
    },
    logoutUser: (state) => {
      state.currentUser = null;
      state.session = null;
    },
  },
});

export const { logoutUser, setSession, setCurrentUserData } = userSlice.actions;
