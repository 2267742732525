import { Navigate, createBrowserRouter } from 'react-router-dom';

import { HomePage } from '@/features/Home';
import { LoginPage } from '@/features/User';

import { AppWrappersInsideRouter } from './AppWrappersInsideRouter';
import { ProtectedRoute } from './ProtectedRoute';
import { RouterErrorHandler } from './RouterErrorHandler';
import { LazyReviewNewGameRulesPage, LazyReviewNewQuestionPage, LazyReviewTranslationPage } from './lazyLoad';
import { Pages } from './pages';

export const router = createBrowserRouter([
  {
    element: <AppWrappersInsideRouter />,
    errorElement: <RouterErrorHandler />,
    children: [
      {
        path: '/',
        element: <Navigate to={Pages.home} />,
      },
      {
        path: Pages.login,
        element: <LoginPage />,
      },
      {
        path: Pages.home,
        element: (
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.reviewNewQuestion,
        element: (
          <ProtectedRoute>
            <LazyReviewNewQuestionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.reviewTranslation,
        element: (
          <ProtectedRoute>
            <LazyReviewTranslationPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Pages.reviewGameRules,
        element: (
          <ProtectedRoute>
            <LazyReviewNewGameRulesPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);
