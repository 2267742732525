import { Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Suspense, lazy } from 'react';

import { Pages } from '@/navigation';

import { ConditionalWrapper } from './ConditionalWrapper';
import { Text } from './Text';

const BlackLogo = lazy(() => import('@/assets/logo/logo-horizontal-black.svg'));
const WhiteLogo = lazy(() => import('@/assets/logo/logo-horizontal-white.svg'));

interface LogoProps {
  linkToHome?: boolean;
}

export const Logo = ({ linkToHome }: LogoProps) => {
  const { palette } = useTheme();
  const isLightMode = palette.mode === 'light';

  return (
    <ConditionalWrapper
      condition={!!linkToHome}
      wrapper={(children) => (
        <Link display="block" href={Pages.home} underline="none">
          {children}
        </Link>
      )}
    >
      <Suspense>
        {isLightMode ? (
          <BlackLogo height="100px" width="fit-content" />
        ) : (
          <WhiteLogo height="100px" width="fit-content" />
        )}
      </Suspense>
      <Box alignItems="center" justifyContent="center" display="flex" mt={-8} mb={8}>
        <Text nonSelectable align="center" variant="headlineSmall" sx={{ textDecoration: 'none' }} color="primary">
          {'Question Reviewer'}
        </Text>
      </Box>
    </ConditionalWrapper>
  );
};
