import { Stack, StackProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type SurfaceVariant =
  | 'transparent'
  | 'surface1'
  | 'surface2'
  | 'surface3'
  | 'surface4'
  | 'surface5'
  | 'secondaryContainer'
  | 'surfaceVariant';

interface Props extends StackProps {
  variant?: SurfaceVariant;
}

export const Surface = ({ variant = 'transparent', ...stackProps }: Props) => {
  const { palette } = useTheme();
  const backgroundColor = variant !== 'transparent' ? palette.app[variant] : 'transparent';

  return <Stack {...stackProps} sx={{ backgroundColor, borderRadius: 4, p: [4, 5] }} />;
};
