import { useCallback } from 'react';

import { useAppDispatch } from '@/hooks';

import { useGetCurrentUserApi } from '../api';
import { setCurrentUserData } from '../store';

export const useRefreshCurrentUserData = () => {
  const dispatch = useAppDispatch();
  const { refetch } = useGetCurrentUserApi();

  const refreshCurrentUserData = useCallback(async () => {
    const currentUserRes = await refetch();

    dispatch(setCurrentUserData(currentUserRes.data || null));

    return currentUserRes.data || null;
  }, [dispatch, refetch]);

  return { refreshCurrentUserData };
};
