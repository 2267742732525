import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useNotify } from '@/hooks';
import { captureError } from '@/services';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { login } from '../api';

import { useSetSessionAndRedirectUser } from './useSetSessionAndRedirectUser';
// import { login } from '../api/login';
// import { setCurrentUserData, setToken } from '../store/userSlice';
// import {
//   removeCredentialsStore,
//   setCredentialsStore,
//   getCredentialsStore,
// } from '../utils/credentialsStore';

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

type LoginFormValues = z.infer<typeof formSchema>;

export const useLoginForm = () => {
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const { setSessionAndRedirectUser } = useSetSessionAndRedirectUser({
    authenticationMethod: 'email',
  });

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit, formState } = form;

  // useEffect(() => {
  //   const getCredentials = async () => {
  //     const credentials = await getCredentialsStore();
  //     if (credentials) {
  //       form.reset({
  //         ...credentials,
  //         rememberMe: true,
  //       });
  //     }
  //   };
  //   getCredentials();
  // }, [form]);

  const onSubmit: SubmitHandler<LoginFormValues> = async (data) => {
    try {
      const { ...payload } = data;
      const { accessToken, refreshToken } = await login(payload);

      setSessionAndRedirectUser(accessToken, refreshToken);
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const { response } = err;
        const { username, password, detail } = response?.data || {};
        const knownError = username?.[0] || password?.[0] || detail;

        if (knownError) {
          notify('error', t([`user:form.${knownError}` as TranslationKeys, 'user:inCorrectCredentials']));
          return;
        }

        if (response?.status === 401) {
          notify('error', t('user:inCorrectCredentials'));
          return;
        }
      }

      notify('error', t('common:errorOccurredTryAgain'));
      captureError(err);
    }
  };

  const onError: SubmitErrorHandler<LoginFormValues> = () => {};

  // const isError = Object.keys(formState.errors).length > 0;

  const onSubmitPress = handleSubmit(onSubmit, onError);

  const isSubmitting = formState.isSubmitting;

  return {
    form,
    onSubmitPress,
    isSubmitting,
    // isError,
  };
};
