type QuestionCommon = {
  id: string;
  content: string;
  content_pl: string;
  content_en: string;
  content_es: string;
  explanation: string;
  explanation_pl: string;
  explanation_en: string;
  explanation_es: string;
  language: string;
  selected: boolean;
  finished: boolean;
  score: number;
};

export type QuestionType = 'notation' | 'multipleChoice' | 'binaryChoice';
export enum QuestionTypeEnum {
  notation = 'notation',
  multipleChoice = 'multipleChoice',
  binaryChoice = 'binaryChoice',
}

export type NotationQuestion = QuestionCommon & {
  restart: string;
  cautionCounter: number;
  sendOffCounter: number;
};

export type MultipleQuestion = QuestionCommon & {
  answerA: string;
  answerA_pl: string;
  answerA_en: string;
  answerB: string;
  answerB_pl: string;
  answerB_en: string;
  answerC: string;
  answerC_pl: string;
  answerC_en: string;
  answerD: string;
  answerD_pl: string;
  answerD_en: string;
  correctAnswer: string[]; // "C,D"
};

export type BinaryQuestion = QuestionCommon & {
  correctAnswer: boolean;
};

export type Question = NotationQuestion | MultipleQuestion | BinaryQuestion;
