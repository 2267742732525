import LanguageIcon from '@mui/icons-material/Language';
import { Box, MenuItem, Select, Stack, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useAppTranslation } from '@/translation';
import { Language } from '@/translation/locales';

interface LanguageSelectorProps {
  variant: 'standard' | 'outlined';
  shouldOpen?: boolean;
  onOpen?: () => void;
  withIcon?: boolean;
}

export const LanguageSelector = ({ variant, shouldOpen, onOpen, withIcon }: LanguageSelectorProps) => {
  const { i18n, currentLanguage } = useAppTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (onOpen) onOpen();
  }, [onOpen]);

  useEffect(() => {
    if (shouldOpen) handleOpen();
  }, [handleOpen, shouldOpen]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const isStandard = variant === 'standard';

  return (
    <Stack direction="row" alignItems="center">
      {withIcon && (
        <IconButton onClick={() => setOpen(true)}>
          <LanguageIcon fontSize="small" />
        </IconButton>
      )}
      <Box width={150} alignContent="center" alignItems="center">
        <Select
          id="language-selector"
          value={currentLanguage}
          onChange={(event) => changeLanguage(event.target.value)}
          fullWidth
          open={open}
          onOpen={handleOpen}
          onClose={() => setOpen(false)}
          size="small"
          variant={variant}
          disableUnderline
          IconComponent={isStandard ? () => null : undefined}
          inputProps={isStandard ? { sx: { pb: 0 } } : {}}
        >
          <MenuItem value={Language.PL}>{'Polski'}</MenuItem>
          <MenuItem value={Language.EN_GB}>{'English'}</MenuItem>
        </Select>
      </Box>
    </Stack>
  );
};
