import loadable from '@loadable/component';

export const LazyReviewNewGameRulesPage = loadable(
  () => import(/* webpackPrefetch: true */ '@/features/ReviewNewGameRules/routes/ReviewNewGameRulesPage'),
  {
    resolveComponent: (components) => components.ReviewNewGameRulesPage,
  },
);

export const LazyReviewNewQuestionPage = loadable(
  () => import(/* webpackPrefetch: true */ '@/features/ReviewNewQuestion/routes/ReviewNewQuestionPage'),
  {
    resolveComponent: (components) => components.ReviewNewQuestionPage,
  },
);

export const LazyReviewTranslationPage = loadable(
  () => import(/* webpackPrefetch: true */ '@/features/ReviewTranslation/routes/ReviewTranslationPage'),
  {
    resolveComponent: (components) => components.ReviewTranslationPage,
  },
);
