import { axiosInstance } from '@/services';

interface RefreshTokenPayload {
  refreshToken: string;
}

interface RefreshTokenResponse {
  access: string | undefined;
  refresh: string | undefined;
}

interface RefreshTokenProcessedResponse {
  accessToken: string | undefined;
}

export const refreshToken = async ({ refreshToken }: RefreshTokenPayload): Promise<RefreshTokenProcessedResponse> => {
  const response = await axiosInstance.post<RefreshTokenResponse>('/api-token-refresh/', {
    refresh: refreshToken,
  });

  return {
    accessToken: response.data.access,
  };
};
