import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { useAppSelector } from '@/hooks';

export const useUpdateSentryContextAboutUser = () => {
  const { currentUser } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.id,
        username: currentUser.username,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [currentUser]);
};
