import { Outlet } from 'react-router-dom';

import { MaterialThemeProvider } from '@/styles/theme';

export const AppWrappersInsideRouter = () => {
  return (
    <div>
      <MaterialThemeProvider>
        <Outlet />
      </MaterialThemeProvider>
    </div>
  );
};
