import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { Text } from '@/components/elements';
import { QuestionType, QuestionTypeEnum } from '@/features/Questions/types';
import { Pages, getPagePath } from '@/navigation';

interface ReviewQuestionTypeButtonProps {
  count: number;
  questionType: QuestionType;
  isLoading: boolean;
  type: 'translation' | 'reviewNewQuestions' | 'reviewNewGameRules';
}

export const ReviewQuestionTypeButton = ({ count, questionType, type, isLoading }: ReviewQuestionTypeButtonProps) => {
  const labelKey = useMemo(() => {
    switch (questionType) {
      case QuestionTypeEnum.binaryChoice:
        return 'home:binaryQuestionCount';
      case QuestionTypeEnum.multipleChoice:
        return 'home:multipleChoiceQuestionCount';
      case QuestionTypeEnum.notation:
        return 'home:notationQuestionCount';
    }
  }, [questionType]);

  const href = useMemo(() => {
    switch (type) {
      case 'translation':
        return getPagePath(Pages.reviewTranslation, { type: questionType, toLanguage: 'es' });
      case 'reviewNewQuestions':
        return getPagePath(Pages.reviewNewQuestion, { type: questionType });
      case 'reviewNewGameRules':
        return getPagePath(Pages.reviewGameRules, { type: questionType });
    }
  }, [questionType, type]);

  return (
    <Stack spacing={1} alignItems="center">
      <Text
        translationKey={labelKey}
        translationParams={{ count: isLoading ? '---' : count }}
        opacity={isLoading ? 0.5 : 1}
        align="center"
      />
      <LoadingButton
        variant="outlined"
        color="primary"
        href={href}
        disabled={count === 0}
        loading={isLoading}
        sx={{ maxWidth: 100 }}
      >
        <Text translationKey="home:review" opacity={isLoading ? 0.2 : 1} />
      </LoadingButton>
    </Stack>
  );
};
