import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/services';

import { NotationQuestion } from '../types';

interface useGetNotationQuestionsParams {
  selected?: boolean;
  finished?: boolean;
  language?: string;
  disabled?: boolean;
}

export const useGetNotationQuestionsApi = ({
  selected,
  finished,
  language,
  disabled,
}: useGetNotationQuestionsParams) => {
  const getNotationQuestions = async () => {
    const response = await axiosInstance.get<NotationQuestion[]>('/notation-questions/', {
      params: {
        selected,
        finished,
        language,
      },
    });

    if (response.status === 404) return null;

    return response.data;
  };

  const {
    data: notationQuestions,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [
      'notationQuestions',
      {
        selected,
        finished,
        language,
      },
    ],
    queryFn: getNotationQuestions,
    enabled: disabled !== true,
    staleTime: 5000, // 5 seconds
  });

  return {
    notationQuestions,
    isLoading: isLoading || isRefetching,
    refetch,
  };
};
