import { ErrorBoundary } from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { SessionManager } from '@/features/User';
import { router } from '@/navigation/router';
import {
  ReactQueryClientProvider,
  AxiosInterceptorsProvider,
  setReduxStoreForAxios,
  initSentry,
  captureError,
} from '@/services';
import { store } from '@/store';

import { useConfigDayjsLang } from './translation';

setReduxStoreForAxios(store);

const messagesOnWhichToReload = ['load component', 'imported module'];

export const App = () => {
  useConfigDayjsLang();

  useEffect(() => {
    initSentry();
  }, []);

  const errorHandler = (error: Error) => {
    const message = error.message.toLowerCase();

    captureError(error);
    // OLD CODE: keep it for now, but it will be removed soon
    // Reload the window to fetch new chunks
    if (messagesOnWhichToReload.some((msg) => message.includes(msg))) window.location.reload();
  };

  return (
    <ErrorBoundary onError={errorHandler}>
      <ReduxProvider store={store}>
        <SnackbarProvider>
          <ReactQueryClientProvider>
            <AxiosInterceptorsProvider store={store}>
              <SessionManager>
                <RouterProvider router={router} />
              </SessionManager>
            </AxiosInterceptorsProvider>
          </ReactQueryClientProvider>
        </SnackbarProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};
