import { CustomVariant, Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode, forwardRef, useMemo } from 'react';

import { ColorPaletteKey } from '@/styles/theme';
import { TranslationKeys, useAppTranslation } from '@/translation';

export interface TextProps extends Omit<TypographyProps, 'color'> {
  nonSelectable?: boolean;
  variant?: CustomVariant;
  opacity?: number;
  translationKey?: TranslationKeys;
  translationParams?: Record<string, string | number | ReactNode>;
  color?: ColorPaletteKey;
  // Will be replaced by color prop
  customColor?: string;
}

export const Text = forwardRef<HTMLInputElement, TextProps>(
  (
    { translationKey, nonSelectable, variant, opacity, children, translationParams, customColor, color, sx, ...props },
    ref,
  ) => {
    const { palette } = useTheme();
    const { t } = useAppTranslation();

    const textColor = useMemo(() => {
      if (color && palette.app && palette.app[color]) return palette.app[color];

      if (customColor) return customColor;
      return undefined;
    }, [color, customColor, palette.app]);

    return (
      <Typography
        {...props}
        variant={variant}
        component="span"
        sx={{ userSelect: nonSelectable ? 'none' : 'text', opacity, color: textColor, ...sx }}
        ref={ref}
      >
        {translationKey ? t(translationKey, translationParams) : children}
      </Typography>
    );
  },
);
